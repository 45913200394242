/********** Template CSS **********/
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@400..700&family=Lato:ital,wght@0,400;0,700;0,900;1,300&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@font-face {
  font-family: myFirstFont;
  src: url(/public/Giulio_Bold.ttf);
}


.giulio_font{
  font-family: myFirstFont;
}


body {
  font-family: "Rubik", sans-serif;
  max-width: 100%;
    overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div,
a {
  font-family: "Rubik", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Rubik", sans-serif;
}

:root {
  --primary: #f68b41;
  --secondary: #51cfed;
  --light: #f8f2f0;
  --dark: #060315;
}

.fw-medium {
  font-weight: 600 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

.whatsapp {
  display: flex;
  align-items: center;
  transition: transform 0.3sec ease-in-out;
  /* border-radius: 10px 10px 10px 10px; */
  border: none;
  background-color: transparent;
  font-size: xx-large;
  padding: 10px;
  margin-left: -20px;
}

.whatsapp-container {
  position: fixed;
  top: 73%;
  left: calc(100% - 55px);
  list-style-type: none;
  padding: 0;
  transform: translateY(-50%);
  width: 250px;
  z-index: 10;
}

.whatsapp-container {
  animation: wiggle 2s linear infinite;
}

.whatsapp-container {
  /* border-radius: 0 0 0.2em 0.2em; */
  color: #fff;
  font-family: Helvetica, Arial, Sans-serif;
  font-size: 1em;
  transform-origin: 50% 5em;
}

.scroll-container{
  position: fixed;
  top: 93%;
  left: calc(100% - 67px);
  list-style-type: none;
  padding: 0;
  transform: translateY(-50%);
  width: 250px;
  z-index: 10;
}

.scroll_icon{
  display: flex;
  align-items: center;
  transition: transform 0.3sec ease-in-out;
  background-color: rgba(230, 240, 230, 0);
  border-radius: 50%;
  border: none;
  font-size: 30px;
  color: rgb(111, 108, 108);
  padding: 10px;
}

.phonecall {
  display: flex;
  align-items: center;
  transition: transform 0.3sec ease-in-out;
  /* border-radius: 10px 10px 10px 10px; */
  /* background-color: rgb(230, 240, 230); */
  background-color: rgba(0, 0, 255, 0);
  border-radius: 50%;
  border: none;
  font-size: 23px;
  color: rgb(4, 57, 248);
  padding: 10px;
  font-weight: 600;
}

.phone-container {
  position: fixed;
  top: 82%;
  left: calc(100% - 63px);
  list-style-type: none;
  padding: 0;
  transform: translateY(-50%);
  width: 250px;
  z-index: 10;
}

.phone-container {
  animation: wiggle 2s linear infinite;
}

.phone-container {
  border-radius: 0 0 0.2em 0.2em;
  color: #fff;
  font-family: Helvetica, Arial, Sans-serif;
  font-size: 1em;
  transform-origin: 50% 5em;
}

/*** Button ***/
.btn {
  font-weight: 600;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.navbar .dropdown-toggle::after {
  /* border: none; */
  /* font-weight: 900; */
  /* vertical-align: middle; */
  /* margin-left: 8px; */
}

.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-right: 20px;
  /* padding: 25px 0; */
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    /* padding: 10px 0; */
    margin-left: 15px;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: 0.5s;
}

@media (min-width: 992px) {
  /* .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    top: -6px;
    left: 50%;
    background: #ffffff;
    transition: 0.5s;
  } */

  .navbar .nav-item .dropdown-menu {
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .nav-item .dropdown-menu {
    background-color: #626262 !important;
    display: block;
    color: #fff !important;
  }

  .nav-item .nav-link:hover {
    color: orange !important;
  }

  .nav-item .dropdown-menu .dropdown-item:hover {
    background-color: #f6ab41 !important;
    color: #000000 !important;
  }

  .nav-item .dropdown-menu .dropdown-item {
    color: #fff !important;
    margin: 0px !important;
    padding: 10px !important;
    padding-left: 10px !important;
    font-size: 15px !important;
    /* font-weight: 00 !important; */
  }

  .dropdown-menu {
    /* width: 180px; */
    border-bottom: 5px solid rgb(252, 170, 7) !important;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
    color: red;

  }
}

/*** Header ***/
@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
    position: relative;
    min-height: auto;
  }

  .header-carousel .owl-carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-carousel .owl-carousel-item h5,
  .header-carousel .owl-carousel-item p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
    font-size: 30px;
    font-weight: 600;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 45px;
  font-size: 22px;
  transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  background: linear-gradient(rgba(6, 3, 21, 0.3), rgba(6, 3, 21, 0.3)),
    url(../public/assets/home_banner_1.png) center center no-repeat;
  background-size: cover;
}

.map {
  /* background: linear-gradient(rgba(6, 3, 21, 0.3), rgba(6, 3, 21, 0.3)),
    url(/assets/banners/Bharat1.png) center center no-repeat; */
  background-size: cover;
}

.engineering-services {
  /* background: linear-gradient(rgba(6, 3, 21, 0.2), rgba(6, 3, 21, 0.2)),
    url(/assets/banners/Engineering_services.png) center center no-repeat; */
  background-size: cover;
  height: 100%;
}

.industrial_cranes {
  /* background: linear-gradient(rgba(6, 3, 21, 0.2), rgba(6, 3, 21, 0.2)),
    url(/assets/banners/Industrial_cranes.png) center center no-repeat; */
  background-size: cover;
  height: 500px;
}

.system_integration {
  /* background: linear-gradient(rgba(6, 3, 21, 0.2), rgba(6, 3, 21, 0.2)),
    url(/assets/banners/system_integration.png) center center no-repeat; */
  background-size: cover;
  height: 500px;
}

.turnkey {
  /* background: linear-gradient(rgba(6, 3, 21, 0.5), rgba(6, 3, 21, 0.5)),
    url(/assets/banners/turnkey.png) center center no-repeat; */
  background-size: cover;
  height: 500px;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: var(--light);
}

/*** About ***/
@media (min-width: 992px) {
  .container.about {
    max-width: 100% !important;
  }

  .about-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .about-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .about-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
    max-width: 100% !important;
  }

  .feature-text {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Service, Price & Team ***/
.service-item,
.price-item,
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
}

.service-item img,
.team-item img {
  transition: 0.5s;
}

.service-item:hover img,
.team-item:hover img {
  transform: scale(1.1);
}

.service-item a.btn-slide,
.price-item a.btn-slide,
.team-item div.btn-slide {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
}

.service-item a.btn-slide i,
.service-item a.btn-slide span,
.price-item a.btn-slide i,
.price-item a.btn-slide span,
.team-item div.btn-slide i,
.team-item div.btn-slide span {
  position: relative;
  height: 40px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  background: var(--primary);
  border-radius: 0 35px 35px 0;
  transition: 0.5s;
  z-index: 2;
}

.team-item div.btn-slide span a i {
  padding: 0 10px;
}

.team-item div.btn-slide span a:hover i {
  background: var(--secondary);
}

.service-item a.btn-slide span,
.price-item a.btn-slide span,
.team-item div.btn-slide span {
  padding-left: 0;
  left: -100%;
  z-index: 1;
}

.service-item:hover a.btn-slide i,
.price-item:hover a.btn-slide i,
.team-item:hover div.btn-slide i {
  border-radius: 0;
}

.service-item:hover a.btn-slide span,
.price-item:hover a.btn-slide span,
.team-item:hover div.btn-slide span {
  left: 0;
}

.service-item a.btn-slide:hover i,
.service-item a.btn-slide:hover span,
.price-item a.btn-slide:hover i,
.price-item a.btn-slide:hover span {
  background: var(--secondary);
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item {
  position: relative;
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  animation: pulse 1s ease-out 0.5s;
}

.testimonial-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: var(--primary);
  border: 5px solid var(--light);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--light);
  border-color: var(--primary);
}

/*** Contact ***/
@media (min-width: 992px) {
  .container.contact-page {
    max-width: 100% !important;
  }

  .contact-page .contact-form {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .contact-page .contact-form {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .contact-page .contact-form {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Footer ***/
.footer {
  /* background: linear-gradient(rgba(6, 3, 21, 0.5), rgba(6, 3, 21, 0.5)),
    url(../img/map.png) center center no-repeat; */
  background-size: cover;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0px;
}

.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 265px;
  transition: 0.7s;
}

.solution_card {
  width: 300px;
}
@media only screen and (min-width: 1024px){

.our_solution_category {
    width: 85%;
    margin: 0 auto;
}
}
.solution_cards_box .solution_card:hover {
  background: #ff3e41;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.solution_cards_box .solution_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_cards_box .solution_card:hover .solu_description button {
  background: #fff !important;
  color: #309df0;
}



.solution_card .solu_title h3 {
  color: #212121;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(140deg,
      #42c3ca 0%,
      #42c3ca 50%,
      #42c3cac7 75%) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_solution_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff00;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
    /* flex-wrap: wrap; */
  }

}

.carousel-inner{
  height: 85vh !important;
}


/* carosel mobile */
@media (orientation:Landscape) {
  .carosel_content{
    position: absolute;
    padding: 15px;
    bottom: 62px !important ;
    left: -10px !important;
    z-index: 30;
    color: #020202 !important; 
      border-radius:10px !important;
     height:60px !important;
      width:auto !important;
       background-color:#00000000 !important;
    }
    .carosel_content h3{
      font-size: calc(1.525rem + 2.3vw) !important;
    }


  .carosel_content_right{
    position: absolute;
    padding: 15px;
    bottom: 50px;
    left: 40px;
    z-index: 30;
    color: #fff !important;
      border-radius:10px !important;
     height:150px !important;
      width:auto !important;
       background-color:#00000000 !important;
       letter-spacing: 1px;
       word-wrap: 5px;
       /* font-family: ; */
  }
  .carosel_content_middle{
    position: absolute;
    padding: 15px;
     left: 560px;
    top: 28%;
    z-index: 30;
    color: #fff !important;
      border-radius:10px !important;
     height:150px !important;
      width:400px !important;
       background-color:#0000005c !important;
  }
  
}


@media screen and (max-width: 480px) {
  .carosel_content {
    display: none !important;
  }
  .carosel_content_middle{
    display: none !important;
  }
  .carosel_content_right{
    display: none;
  }
  .carousel-inner{
    height: auto !important;
  }

 
}

@media screen and (max-width: 1024px) {
  .carosel_content{
    bottom: 38px !important ;
    left: 35px !important;
  }
  .carosel_content_right{
    bottom: 40px;
    right: 30px;
  }
  .carosel_content_middle{
    left: 45% !important;
    top: 40% !important;
  }
  .carousel-inner{
    height: 70vh !important;
  }
}



@media screen and (max-width: 990px) {
  .carosel_content{
    bottom: 38px !important ;
    left: 35px !important;
  }
  .carosel_content_right{
    bottom: 40px;
    right: 30px;
  }
  .carosel_content_middle{
    left: 45% !important;
    top: 40% !important;
  }
  .carousel-inner{
    height: 60vh !important;
  }
}


@media screen and (max-width: 768px) {
  .carosel_content{
    position: absolute;
    padding: 15px;
    bottom: 40px;
    left: 30px;
    z-index: 30;
    color: #fff !important;
      border-radius:10px !important;
     height:150px !important;
      width:400px !important;
       background-color:#00000000 !important;
    }
  .carosel_content_right{
    position: absolute;
    padding: 15px;
    bottom: 40px;
    right: 30px;
    z-index: 30;
    color: #fff !important;
      border-radius:10px !important;
     height:150px !important;
      width:400px !important;
       background-color:#00000000 !important;
  }
  .carosel_content_right h4{
    color: #fff;
  }
  .carosel_content_middle{
    position: absolute;
    padding: 15px;
     left: 320px;
    top: 28%;
    z-index: 30;
    color: #fff !important;
      border-radius:10px !important;
     height:150px !important;
      width:400px !important;
       background-color:#00000000 !important;
  }
  .carousel-inner{
    height: 42vh !important;
  }
}





@media only screen and (max-width: 480px) {
  .our_solution_category .solution_cards_box {
    flex-direction: column;
    align-items: center;
  }
  .carousel-inner{
    height: 14vh !important;
  }
  .solution_card {
    width: 350px;
  }
}

@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }

  .solution_card {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }

  .our_solution_category {
    width: 80%;
    margin: 0 auto;
  }
}

.card:hover {
  background-color: #f58230;
}
.dropdown-item .icon {
  margin-right: 8px !important;
 } 
.dropdown-item:hover .icon {
  color: black !important;
}
/* turnkey */
/* .dropdown-item:hover {
  background-color: #f68b41 !important;
}

.dropdown-item .icon {
 margin-right: 10px !important;
} */

/* .dropdown-item {
  margin-top: 6px;
} */

.industryIcon:hover {
  /* transform: skewX(-10deg); */
  transform: rotate(5deg) scale(1.1);
  
  transition: all .5s;
}


/* Our teams */
.our_team {
  background-image: url("https://btrpay.com/wp-content/uploads/2020/05/Why-Team-Members-Work-Better-When-They-Care-For-Each-Other-scaled.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: -96px;
}

.our_team button {
  background-color: rgba(246, 140, 65, 0.551) !important;
  border-radius: none !important;
  font-size: 35px !important;
  font-weight: 400;
}

.our_team p {
  background-color: rgba(246, 140, 65, 0.581) !important;
  border-radius: none !important;
  font-size: 20px !important;
  color: #fff;
  font-weight: 400;
}


.home_link .nav-link{
  color: white !important;
  font-size: 18px;
  font-weight: 400;
}
.home_link .nav-link:hover{
  color: #f68b41 !important;
}
.ourMission_content:hover{
  transform: scale(1.1);
 transition: all 1s;
}
.ourMission_content:hover p{
  background-color: #f68b41;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  color: #fff !important;
}
.ourMission_content:hover span{
  color: #fff !important;
}

@media only screen and (max-width: 480px) {
  .home_link .nav-link{
    color: white !important;
    font-size: 11px;
    font-weight: 300;
  }
  .video_popup{
    /* height: 5% !important; */
    top: 63px !important;
    left: 0px !important;
  }
  .video_img img{
    height: 80% !important;
    width: 43% !important;
  }
  .video_img{
    height: 50% !important;
  }
  .home_link_container{
    height: 50% !important;
  }
}










.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
    list-style-type: none;
  display: none;
  position: absolute;
  background-color: #626262;
  width: 240px;
  padding: 1px;
  padding-bottom: 8px;
  left: 100%;
  top: -7px;
  border-bottom: 5px solid rgb(252, 170, 7) !important;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  }
  
  .dropdown-hover:hover>.dropdown-menu {
  display: inline-block;
  }
  
  .dropdown-hover>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
  }
  


